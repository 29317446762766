import React from 'react';
import { graphql } from 'gatsby';
import { Col, Row } from 'styled-bootstrap-grid';
// import styled from 'styled-components';

// Previews
import { withPreview } from 'gatsby-source-prismic';

// Components
import { SingleArtistAnimationVideo } from '../components/Artists/single-artist-animation-video';

// Icons
// import { PrevArrow } from '../components/Icons/news-article-icons';

// const BackArrow = styled.div`
//   margin: 25px 0 0 0;

//   & svg {
//     width: 50px;
//   }
//   @media (max-width: 767px) {
//     margin: 25px 0;

//     & svg {
//       width: 30px;
//     }
//   }
// `;

const Animation = ({ location, data }) => {
  return (
    <>
      <Row justifyContent="center">
        {/* {data.prismicAnimation.data.artist.document !== null && (
          <Col col={12}>
            <BackArrow>
              <div className="back-arrow">
                <Link
                  to={`/artists/${data.prismicAnimation.data.artist.document.uid}/animation/`}
                >
                  <PrevArrow />
                </Link>
              </div>
            </BackArrow>
          </Col>
        )} */}

        <Col col={12} md={10} lg={8}>
          <SingleArtistAnimationVideo
            artist={
              data.prismicAnimation.data.artist.document !== null
                ? data.prismicAnimation.data.artist.document
                : null
            }
            showreel={data.prismicAnimation.data.video}
            videoTitle={data.prismicAnimation.data.video_title}
            videoClient={data.prismicAnimation.data.video_client}
          />
        </Col>
      </Row>
    </>
  );
};

export default withPreview(Animation);

export const query = graphql`
  query SingleArtistAnimationQuery($uid: String!) {
    prismicAnimation(uid: { eq: $uid }) {
      data {
        artist {
          document {
            ... on PrismicArtist {
              id
              uid
              data {
                name {
                  text
                }
              }
            }
          }
        }
        video_title
        video_client
        video {
          embed_url
          thumbnail_url
          width
          height
          provider_name
        }
      }
    }
  }
`;
