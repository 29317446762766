import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

// Icons
import { PrevArrow } from '../Icons/news-article-icons';

const BackArrow = styled.div`
  margin: 25px 0 0 0;

  & a {
    display: block;
    margin: 0 auto;
    width: fit-content;
  }

  & svg {
    width: 50px;
  }
  @media (max-width: 767px) {
    margin: 25px 0;

    & svg {
      width: 30px;
    }
  }
`;

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: ${(props) => props.aspectRatio + `%`};
  margin-bottom: 30px;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ShowreelContainer = styled.div`
  position: relative;

  & h1,
  & h2 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: 0.4px;

    font-family: 'BatonTurbo', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    text-transform: capitalize;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (max-width: 500px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const Poster = styled.div`
  & img {
    z-index: 40;

    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const TitleContainer = styled.div`
  padding: 100px 0;

  @media (max-width: 767px) {
    padding: 50px 0;
  }

  @media (max-width: 500px) {
    padding: 10px 0 50px 0;
  }
`;

async function getVimeoThumbnail(video, width, height) {
  let response = await fetch(
    `https://vimeo.com/api/oembed.json?url=${video}&width=${width}&height=${height}&quality=1080`
  );

  let data = await response.json();
  return data;
}

export const SingleArtistAnimationVideo = ({
  artist,
  showreel,
  videoTitle,
  videoClient,
}) => {
  const [isPosterVisible, setPosterVisible] = useState(true);
  const [thumbnail, setThumbnail] = useState(null);

  let vimeoThumbnail =
    showreel.embed_url !== null
      ? getVimeoThumbnail(showreel.embed_url, 1920, 1080)
      : null;

  useEffect(() => {
    if (vimeoThumbnail !== null) {
      vimeoThumbnail.then((result) => {
        setThumbnail(result.thumbnail_url);
      });
    }
  }, [vimeoThumbnail]);

  if (showreel.embed_url !== null) {
    return (
      <ShowreelContainer>
        <IframeContainer aspectRatio={(showreel.height / showreel.width) * 100}>
          {isPosterVisible && thumbnail !== null && (
            <Poster>
              <img src={thumbnail} alt="Thumbnail" />
            </Poster>
          )}
          <ReactPlayer
            url={showreel.embed_url}
            width="100%"
            height="100%"
            playing={true}
            controls
            loop={true}
            playsinline={true}
            autoPlay={true}
            volume={0}
            muted={true}
            onStart={() => {
              setPosterVisible(false);
            }}
            config={{
              vimeo: {
                playerOptions: {
                  background: 1,
                  loop: 1,
                  autoPlay: 1,
                  muted: 1,
                },
              },
            }}
          />
        </IframeContainer>

        {artist !== null && (
          <BackArrow>
            <Link to={`/artists/${artist.uid}/animation/`}>
              <PrevArrow />
            </Link>
          </BackArrow>
        )}

        {/* <div className="video-title">
          <h1>{videoTitle}</h1>

          {artist !== null && (
            <h2>
              <Link to={`/artists/${artist.uid}/animation/`}>
                {artist.data.name.text}
              </Link>
            </h2>
          )}
        </div> */}
      </ShowreelContainer>
    );
  } else {
    return null;
  }
};
